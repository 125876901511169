import { ObjectValues } from "@addiction_dp/datalean-nodejs-shared";

export const STANDARD_PRACTICE_SECTION_TYPE = {
    TEXT: 'text',
    Q_AND_A: 'question_and_answer',
} as const;

export type StandardPracticeSectionType = ObjectValues<typeof STANDARD_PRACTICE_SECTION_TYPE>;


// Definiammo la StandardPracticeSection come una union type in modo che sia più facile tipizzare
// il value delle section a seconda del suo type
// (es: se il type è uguale a question_and_answer, allora il suo value deve essere di tipo {question, answer}
export type StandardPracticeSection = StandardPracticeSectionText | StandardPracticeSectionQAndA;

export interface StandardPracticeSectionText {
  type: (typeof STANDARD_PRACTICE_SECTION_TYPE)['TEXT'];
  value: string,
  name: string
}

export interface StandardPracticeSectionQAndA {
  type: (typeof STANDARD_PRACTICE_SECTION_TYPE)['Q_AND_A'];
  value: {question: string, answer: string},
  name: string
}
