import { ObjectValues } from "@addiction_dp/datalean-nodejs-shared";


export const ENTITY_STATUSES = {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
    DELETED: "DELETED",
    DISABLED: "DISABLED",
    PUBLISHED: "PUBLISHED",
    DRAFT: "DRAFT",
    ARCHIVED: "ARCHIVED",
} as const;

export type EntityStatuses = ObjectValues<typeof ENTITY_STATUSES>;