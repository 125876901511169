import { DataleanEntity, ObjectValues } from '@addiction_dp/datalean-nodejs-shared';
import { ENTITY_STATUSES } from './entity-status';

export const PRACTICE_SECTION_STATUS = {
    PUBLISHED: ENTITY_STATUSES.PUBLISHED,
    DRAFT: ENTITY_STATUSES.DRAFT,
    ARCHIVED: ENTITY_STATUSES.ARCHIVED,
} as const;

export type PracticeSectionStatus = ObjectValues<typeof PRACTICE_SECTION_STATUS>;

export interface PracticeSectionInterface extends DataleanEntity {
  name: string;
  value: string;
  version: number;
  sectionStatus: PracticeSectionStatus;
}
