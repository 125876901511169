import { Asset, DataleanEntity, ObjectValues } from "@addiction_dp/datalean-nodejs-shared";
import { StandardPracticeSection } from "./standard-practice-section-interface";
import { PracticeSectionInterface } from "./practice-section-interface";
import { PatientInterface } from "./patient.model";

//documenti che i medici fanno firmare ai pazienti

export interface PracticeInterface extends DataleanEntity {
  name: string;
  practiceDate: string | Date
  practiceStatus: PracticeStatus
  patientInfo: PracticePatientInfo,
  assetsUUID: string[]
  sections: PracticeSectionRef[]
  medicUUID: string
  //modello/template dal quale si è partiti per creare la pratica, è opzionale e non si sa ancora bene perchè lo vogliamo (scopi statistici)
  templateUUID?: string
  //campi calcolato con il parts
  practiceSections?: PracticeSectionInterface[]
  patient?: PatientInterface
  assets?: Asset[]
}

export type PracticeSectionRef = PracticeSectionObject | StandardPracticeSection

export const PRACTICE_STATUS = {
  SIGNED: "SIGNED",
  UNACCEPT_SIGNED: "UNACCEPT_SIGNED",
  DRAFT: "DRAFT",
  WAITING_SIGN: "WAITING_SIGN",
} as const;

export type PracticeStatus = ObjectValues<typeof PRACTICE_STATUS>;

export interface PracticePatientInfo {
  uuid: string,
  fullName: string,
  name: string,
  lastName: string,
  fiscalCode: string,
  birthInfo: {
    city: string
    province: string
    date: string | Date
  }
  residencyInfo: {
    city: string
    province: string
    address: string
  }
}

export interface PracticeSectionObject {
  sectionRef: {
    uuid: string
    version: number
  }
  value: string
}
