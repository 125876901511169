import { PracticeInterface, PracticeSectionObject } from "../practice-interface"
import { PracticeSectionInterface } from "../practice-section-interface"
import { STANDARD_PRACTICE_SECTION_TYPE, StandardPracticeSection, StandardPracticeSectionQAndA, StandardPracticeSectionText } from "../standard-practice-section-interface"

export function isPracticeSectionStandard(element: unknown): element is StandardPracticeSection {
  return !!element && typeof element === "object" && 'type' in element && 'value' in element
}

export function isPracticeSectionStandardQAndA(element: unknown): element is StandardPracticeSectionQAndA {
  return isPracticeSectionStandard(element) && element.type === STANDARD_PRACTICE_SECTION_TYPE.Q_AND_A
}

export function isPracticeSectionStandardText(element: unknown): element is StandardPracticeSectionText {
  return isPracticeSectionStandard(element) && element.type === STANDARD_PRACTICE_SECTION_TYPE.TEXT
}

export function isPracticeSectionObject(element: PracticeSectionObject | StandardPracticeSection): element is PracticeSectionObject {
  return 'sectionRef' in element
}

export function isPracticeSection(element: unknown): element is PracticeSectionInterface {
  return !!element && typeof element === "object" && 'name' in element && 'value' in element && 'version' in element && 'sectionStatus' in element
}

export function isPractice(element: unknown): element is PracticeInterface {
  return !!element && typeof element === 'object' && 'patientInfo' in element && 'practiceStatus' in element;
}

